<template>
  <div class="home fill-height">
    <v-app-bar app clipped-left clipped-right flat dense color="primary" class="white--text">
      <v-toolbar-title class="text-uppercase">Eric de Groot</v-toolbar-title>
    </v-app-bar>
    <v-content style="height: 100vh; height: calc(var(--vh, 1vh) * 100); overflow:hidden"
               :style="$vuetify.breakpoint.mdAndUp && 'width: 60%;'">
      <v-container class="fill-height pa-0" fluid>
        <v-row class="fill-height">
          <v-col cols="12" class="pt-0 fill-height">
            <ContactInfo />
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </div>
</template>

<script>
// @ is an alias to /src
import ContactInfo from '@/components/ContactInfo.vue'

export default {
  name: 'Home',
  metaInfo: {
    title: 'Contact',
  },
  components: {
    ContactInfo,
  }
}
</script>

<style>
  .logo {
  float: left;
  }

.v-toolbar__title {
  font-size: 1.5rem !important;
}
</style>
