<template>
  <div class="badge-base LI-profile-badge" data-locale="en_US" data-size="large" data-theme="light" data-type="HORIZONTAL" data-vanity="eric-de-groot-82ab10211" data-version="v1"><!--<a class="badge-base__link LI-simple-link" href="https://www.linkedin.com/in/eric-de-groot-82ab10211?trk=profile-badge">Eric de Groot</a>--></div>
</template>

<script>
export default {
  name: 'LinkedInProfileBadge',
  created() {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://platform.linkedin.com/badges/js/profile.js';
    scriptTag.setAttribute('type', 'text/javascript');
    scriptTag.setAttribute('async', true);
    scriptTag.setAttribute('defer', true);
    document.head.appendChild(scriptTag);
  },
};
</script>
