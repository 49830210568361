<template>
  <v-container fluid class="ma-1">
    <v-row>
      <v-col :cols="12">
        Please contact me on the following social media platforms:
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <LinkedInProfileBadge />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import LinkedInProfileBadge from './LinkedInProfileBadge';

  export default {
    name: 'ContactInfo',
    components: {
      LinkedInProfileBadge,
    },
  }
</script>
