<template>
  <v-app id="inspire">
    <router-view/>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Eric de Groot',
    titleTemplate: '%s - Eric de Groot',
  },
};
</script>
